import "./AboutMe.css";

export default function AboutMe() {
  return (
    <div className="aboutMeContainer">
      <h1 className="title"> Scurtă Prezentare</h1>
      <div className="quote-container">
        <div className="quote-image">
          <img
            src={
              "https://res.cloudinary.com/dhykfmali/image/upload/w_300,q_80/avocat-quote_ccdz6l.webp"
            }
            alt="Avocat"
          />
        </div>
        <div className="quote-text">
          <i
            className="fa-solid fa-quote-left"
            style={{ fontSize: "5rem", color: " #f0d9a0" }}
          ></i>
          <p>
            Am reprezentat și asistat clienți în cazuri de încălcare a
            drepturilor de către autorități sau terți. Specializat în drept
            penal și civil, ofer servicii de calitate, inclusiv și în dreptul
            internațional. Caut mereu soluții eficiente pentru a restabili
            echilibrul în situații dificile, accentuând transparența și
            comunicarea. Abordarea mea personalizată vizează soluții optime
            pentru fiecare caz. Dacă aveți nevoie de asistență legală, sunt aici
            pentru a vă proteja drepturile.
          </p>
          <i
            className="fa-solid fa-quote-right"
            style={{ fontSize: "5rem", color: " #f0d9a0" }}
          ></i>
        </div>
      </div>
    </div>
  );
}
