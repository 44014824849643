import React from "react";
import { Link } from "react-router-dom";
import "./NotFound.css";

const NotFound = () => {
  return (
    <div className="not-found-container">
      <div className="not-found-content">
        <h1 className="not-found-title">404 - Pagina nu a fost găsită</h1>
        <p className="not-found-text">
          Ne pare rău, dar pagina pe care o căutați nu există.
        </p>
        <Link to="/" className="not-found-link">
          Înapoi la Pagina Principală
        </Link>
      </div>
    </div>
  );
};

export default NotFound;
