import './cookiesConsent.css'

import Cookies from 'js-cookie'



export default function CookiesConsent({setConsent}) {

  return (
    <>
      
        <div className="cookie-consent">
          <p>
            

          Acest site folosește cookie-uri pentru a îmbunătăți experiența dvs. Prin navigare acestui site, presupune acceptarea lor. 

          </p>
          <button onClick={()=>{

            Cookies.set('consent', true , {expires : 365})
            setConsent(Cookies.get('consent'))
          }} >Accept Cookies</button>
        </div>
     
      </>)
}
