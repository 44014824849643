import "./Home.css";
import { Link } from "react-router-dom";
import AboutMe from "../AboutMe/AboutMe";
import Items from "../Items/Items";
import Steps from "../Steps/Steps";
import Promo from "../Promo/Promo";

export default function Home() {
  return (
    <div className="home">
      <div className="banner-container">
        <div className="banner">
          <h1 className="title banner-title">Serviciu de consultanță</h1>
          <p className="banner-text">
            În calitate de avocat independent, ofer consultanță juridică
            personalizată clienților individuali. Indiferent dacă preferați să
            colaborăm online sau să aveți o întâlnire în cabinetul meu, vă
            asigur că veți beneficia de o abordare atentă și de o experiență de
            consultanță de încredere. Cu expertiză în diverse domenii juridice,
            sunt angajat să vă îndrum prin aspectele legale cu soluții eficiente
            și limpezi. Nevoile dumneavoastră sunt prioritatea mea, indiferent
            de modalitatea de consultanță aleasă.
          </p>
          <Link  to='/contact' className="redirect redirect-home">Solicită o programare</Link>

        </div>
      </div>

      
        <AboutMe />
      <Items />
      <Steps />
      <Promo />

    </div>
  );
}
