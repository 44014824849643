import "./Steps.css";

export default function Steps() {
  return (
    <div className="steps-container">
      <span className="steps-title">
        {" "}
        <h1 className="title" style={{ color: "black" }}>
          Calea Ta în Justiție:
        </h1>{" "}
        <h1 className="title" style={{ textDecoration: "underline" }}>
          3 Etape Esențiale
        </h1>{" "}
      </span>
      <p className="steps-description">
        Profesând într-un cadru juridic volatil, unde regulile pot suferi
        schimbări neprevăzute, asigurăm că fiecare etapă este adaptată la
        soluțiile legislative actuale.
      </p>
      <div className="steps">
        <div className="step-box">
          <h1>1</h1>
          <div className="step-box-right">
            <h2>DISCUȚIE PERSONALIZATĂ</h2>
            <p>
              În primul rând, vom purta o discuție deschisă și personalizată
              pentru a înțelege în detaliu situația cu care vă confruntați.
            </p>
          </div>
        </div>
        <div className="step-box">
          <h1>2</h1>
          <div className="step-box-right">
            <h2>ANALIZĂ AMĂNUNȚITĂ</h2>
            <p>
              Pasul următor implică o analiză atentă a tuturor informațiilor și
              documentelor pe care le aveți, pentru a avea o imagine de ansamblu
              completă.
            </p>
          </div>
        </div>
        <div className="step-box">
          <h1>3</h1>
          <div className="step-box-right">
            <h2>REPREZENTARE DIRECTĂ</h2>
            <p>
              Vă voi reprezenta personal în fața instanței, asigurându-mă că
              drepturile dumneavoastră sunt apărate în mod corespunzător în
              cadrul procesului judiciar.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
