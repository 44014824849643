import "./Items.css";

export default function Items() {
  return (
    <div className="items">
      <h1 className="title">Domenii de activitate</h1>

      <div className="ads-container">
        <div className="item-container">
          <div className="item-title">
            <i
              className="fa-solid fa-file-signature fa-2xl"
              style={{ color: "#f0d9a0", marginTop: ".5rem" }}
            ></i>{" "}
            <h1>Drept Civil</h1>
          </div>

          <p>
            Îmi dedic eforturile consultanței și asistenței juridice în diverse
            situații legate de sfera civilă. De la litigii privind proprietatea,
            contracte sau succesiuni sunt hotărât să apăr drepturile și să
            găsesc soluții optime pentru fiecare caz.
          </p>
        </div>
        <div className="item-container">
          <div className="item-title">
            <i
              className="fa-solid fa-scale-balanced fa-2xl"
              style={{ color: "#f0d9a0", marginTop: ".5rem" }}
            ></i>{" "}
            <h1>Drept Penal</h1>
          </div>

          <p>
            Îmi canalizez eforturile către consultanța și asistența juridică în
            sfera dreptului penal. Fie că este vorba de infracțiuni sau de
            apărarea acuzațiilor, sunt hotărât să garantez respectarea
            drepturilor și să găsesc soluții optime pentru fiecare caz.
          </p>
        </div>{" "}
        <div className="item-container">
          <div className="item-title">
            <i
              className="fa-solid fa-pen fa-2xl"
              style={{ color: "#f0d9a0", marginTop: ".5rem" }}
            ></i>{" "}
            <h1>Drept Comercial</h1>
          </div>

          <p>
            Cu o expertiză în dreptul comercial, ofer consultanță și asistență
            în diverse aspecte ale afacerilor. De la înființarea și structurarea
            afacerii până la gestionarea contractelor, misiunea mea este să
            sprijin succesul și să găsesc soluții optime.
          </p>
        </div>{" "}
        <div className="item-container">
          <div className="item-title">
            <i
              className="fa-solid fa-file-invoice-dollar fa-2xl"
              style={{ color: "#f0d9a0", marginTop: ".5rem" }}
            ></i>{" "}
            <h1>Drept Fiscal</h1>
          </div>

          <p>
            Cu o competență solidă în dreptul fiscal, ofer consultanță și
            asistență în diverse aspecte fiscale. De la optimizarea activității
            la gestionarea obligațiilor fiscale și disputelor cu autoritățile
            fiscale, misiunea mea este să sprijin în navigarea legislației și să
            ajut să luați decizii informate.
          </p>
        </div>
        <div className="item-container">
          <div className="item-title">
            <i
              className="fa-solid fa-person-digging fa-2xl"
              style={{ color: "#f0d9a0", marginTop: ".5rem" }}
            ></i>{" "}
            <h1>Dreptul Muncii</h1>
          </div>

          <p>
            Cu o expertiză în dreptul muncii, ofer consultanță și asistență în
            aspecte variate ale relațiilor de muncă. De la redactarea
            contractelor de muncă și gestionarea aspectelor legale ale
            angajărilor până la rezolvarea disputelor cu angajații, misiunea mea
            este să sprijin relații conforme cu reglementările și drepturile
            angajaților.
          </p>
        </div>
        <div className="item-container">
          <div className="item-title">
            <i
              className="fa-solid fa-people-roof fa-2xl"
              style={{ color: "#f0d9a0", marginTop: ".5rem" }}
            ></i>{" "}
            <h1>Dreptul Familiei</h1>
          </div>

          <p>
            Cu o cunoaștere profundă a dreptului familiei, ofer consultanță și
            asistență în aspecte variate ale relațiilor familiale. De la divoriț
            și drepturi părintești la custodia minorilor și patrimoniu familial,
            misiunea mea este să sprijin în navigarea legilor familiei și să
            obțin rezultate favorabile.
          </p>
        </div>
      </div>
    </div>
  );
}
