import "./NavBar.css";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import AdminLoginButton from "../AdminLoginButton/AdminLoginButton ";
import Cookies from "js-cookie";
export default function NavBar(props) {
  const [revealClickCount1, setRevealClickCount1] = useState(0);
  const [revealClickCount2, setRevealClickCount2] = useState(0);
  const [revealed, setRevealed] = useState(false);

  const handleRevealClick = (props) => {
    if (revealClickCount1 === 5 && revealClickCount2 === 5) {
      setRevealed(true);
    } else {
      setRevealed(false);
    }
  };

  useEffect(() => {
    handleRevealClick();
  }, [revealClickCount1, revealClickCount2]);

  return (
    <nav>
      <div className="logo-nav-container">
        <img
          className="logo-image"
          alt="logo avocat florin negutu"
          src={
            "https://res.cloudinary.com/dhykfmali/image/upload/w_300,q_80/logo_negutu.webp"
          }
          onClick={() => {
            setRevealClickCount1((prev) => prev + 1);
          }}
        />
        <img
          className="barou-image"
          alt="logo baroul bucuresti"
          src={
            "https://res.cloudinary.com/dhykfmali/image/upload/w_300,q_80/barou_hkglvt.webp"
          }
          onClick={() => {
            setRevealClickCount2((prev) => prev + 1);
          }}
        />
      </div>

      <div className="redirect-nav-container">
        <Link className="redirect nav-redirect" to="/">
          Acasă
        </Link>
        <Link className="redirect nav-redirect" to="/blog">
          Blog
        </Link>
        <Link
          className="redirect nav-redirect"
          to="/contact"
          onClick={(e) => {
            e.preventDefault();
            props.getContact(true);
            window.location.href = "/contact";
          }}
        >
          Contact
        </Link>
        {Cookies.get("login") && Cookies.get("admin") && (
          <Link className="redirect nav-redirect" to="/admin">
            Admninistrator
          </Link>
        )}
        {revealed && !Cookies.get("login") && <AdminLoginButton />}
      </div>
    </nav>
  );
}
