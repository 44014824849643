import "./Footer.css";
import MapComponent from "../MapComponent/MapComponent";

export default function Footer() {
  return (
    <div className="footer-container">
      <div className="upper-footer">
        <div className="contact">
          <h1 className="contact-title">Contact</h1>

          <ul className="footer-list-upper">
            <li>
              <i class="fa-solid fa-mobile icon"></i>{" "}
              <p> Mobil: 0743 850 428</p>
            </li>
            <li>
              {" "}
              <i class="fa-solid fa-envelope icon"></i>{" "}
              <p>Mail: fnegutu@gmail.com</p>
            </li>
            <li>
              <i class="fa-solid fa-location-pin icon"></i>{" "}
              <p>Adresă: București, Sector 6, Calea Giulești nr.24</p>
            </li>
            {/* <li style={{
              flexDirection: "column",
              alignItems: 'baseline'
            }}>
         
            </li> */}
          </ul>
        </div>
        <div className="contact">
        <h1 className="contact-title">Orar</h1>
        <ul className="footer-list-upper">

        <li >Luni - Joi: 12:00 - 18:00</li>
              <li>Vineri: 12:00 - 17:00</li>
              <li>Sâmbătă - Duminica: Închis</li>
        </ul>
             
        </div>
        <div className="maps-footer">
          <h2 className="contact-title">Hartă</h2>
          <MapComponent />
        </div>
      
      </div>
      <div className="lower-footer">
        <div className="credit">
           <p> 
 © AVOCATNEGUTUFLORIN.RO {new Date().getFullYear()}</p> 
 <p>Website made with love by <a href="https://www.linkedin.com/in/andrei-vasile-negutu-0a21a8211/"> Neguțu Andrei</a></p>

          <p>
                  
            <a href="https://www.freepik.com/free-photo/liquid-marbling-paint-texture-background-fluid-painting-abstract-texture-intensive-color-mix-wallpaper_22147843.htm">
              Image by benzoix
            </a>
           {' '}  on Freepik
          </p>

        </div>
      </div>
    </div>
  );
}
