import React from "react";
import { GoogleMap, LoadScript, Marker } from "react-google-maps-api";

const MapComponent = () => {
  const location = {
    lat: 44.45751192146644,
    lng: 26.05419769638358,
  };

  return (
    <div className="map-container">
      <LoadScript googleMapsApiKey="AIzaSyAhSParrH5wSwXxQv1Qzq4wkLFm8ROZlTo">
        <GoogleMap
          options={{
            mapTypeControl: false,
            streetViewControl: false,
            fullscreenControl: false,

            styles: [
              {
                featureType: "landscape",
                elementType: "geometry",
              },
            ],
          }}
          center={location}
          zoom={18}
          mapContainerStyle={{ height: "20rem" , position: 'relative'}}
        >
          <Marker position={location} />
        </GoogleMap>
      </LoadScript>
    </div>
  );
};

export default MapComponent;
