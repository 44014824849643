import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Cookies from "js-cookie";

const AdminLoginButton = () => {
  const handleAdminLoginClick = async () => {
    const enteredKey = prompt("Introduceți cheia de autentificare:");

    if (enteredKey) {
      try {
        const response = await axios.post("api/secret/admin/login", {
          key: enteredKey,
        });
        if (response.status == 200) {
          const data = response.data;
          Cookies.set("adminToken", data.token);
          Cookies.set("login", true);
          Cookies.set("admin", true);
          window.location.href = "/admin";
        }
      } catch (error) {
        if(error.response.status == 429){
         alert(error.response.data)
    }
        alert("Autentificare eșuată");
      }
    }
  };

  return (
    <div>
      <button
        className="redirect nav-redirect"
        style={{ border: "none", backgroundColor: "transparent" }}
        onClick={handleAdminLoginClick}
      >
        Autentificare Admin
      </button>
    </div>
  );
};

export default AdminLoginButton;
