import React from "react";
import { Link } from "react-router-dom";
import "./Promo.css"; // Importă stilurile CSS pentru componentă

const Promo = () => {
  return (
    <div className="promo-container">
      <div className="left-promo">
        <h1>Nu te-ai hotărât încă?</h1>

        <h2>
          Daca înregistrezi o programare online ai urmatoarele beneficii
          incluse:
        </h2>
        <ul>
          <li>15 min consultanță online gratuită</li>
          <li>30 min consultanță la birou gratuită</li>
        </ul>
      </div>
      <Link to="/contact" className="redirect redirect-promo">
        Solicită o programare
      </Link>
    </div>
  );
};

export default Promo;
