import "./App.css";
import React, { Suspense, useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import NavBar from "./NavBar/NavBar";
import Home from "./Home/Home";
import Footer from "./Footer/Footer";
import NotFound from "./NotFound/NotFound";
import Spinner from "./Spinner/Spinner";
import CookiesConsent from "./CookiesConsent/CookiesConsent";
import Cookies from "js-cookie";
const Appoitment = React.lazy(() => import("./Appoitment/Appoitment"));
const AdminDashboard = React.lazy(() =>
  import("./AdminDashboard/AdminDashboard")
);
const ArticlePage = React.lazy(() => {
  return import("./ArticlePage/ArticlePage");
});
const BlogPage = React.lazy(() => {
  return import("./BlogPage/BlogPage");
});

function App() {
  const [showContact, setShowContact] = useState(false);
  const [consent, setConsent] = useState(Cookies.get("consent"));
  const getContact = (value) => {
    setShowContact(value);
  };
  // Cookies.remove(`consent`)


  return (
    <div className="App">
      <NavBar getContact={getContact} />
      <main>
        <Suspense fallback={<Spinner />}>
          <Routes>
            <Route path="/" element={<Home />} />
            {/* <Route path="/admin/article" element={<ArticleEditor />} /> */}

            <Route path="*" element={<NotFound />} />
            <Route path="/admin" element={<AdminDashboard />} />
            <Route path="/contact" element={<Appoitment />} />
            <Route path="/article/:id" element={<ArticlePage />} />
            <Route path="/blog" element={<BlogPage />} />
          </Routes>
        </Suspense>
      </main>
      <footer>
        <Footer />
      </footer>
      {consent == undefined && <CookiesConsent setConsent={setConsent} />}
    </div>
  );
}

export default App;
